.nav-header {
    padding: 1rem .5rem;
    background-color: #05A6CC;

    a {
        color: #fff;
        text-decoration: none;
    }
}

.video-details {
    background-color: #05A6CC;
    text-align: center;
    color: #FFF;
    padding: 2rem 2rem 1rem 2rem;

    p {
        margin: 0;
    }

    .h5 {
        font-weight: bold;
    }

    hr {
        border-top: 1px solid #fff;
        width: 80%;
        max-width: 611px;
        margin: 1.5rem auto 1rem auto;
        @include media-breakpoint-between(xs,sm){
            margin: 1rem auto 0.8rem auto;
        }
    }
    .btn {
        max-width: 250px;
    }
}
.embed-container {
    background-color: #05A6CC;

}


