html, body {
    min-height: 100%;
    background-color: $bg-color;
}

@include media-breakpoint-between(xs,sm){
    .btn {
        @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }

    .btn-force-lg {
        @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
    }
}
