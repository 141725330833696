$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";

$font-family-base: 'Nunito', sans-serif;
$btn-border-radius: 50rem;
$btn-border-radius-sm: 50rem;
$btn-border-radius-lg: 50rem;
$btn-border-width: 2px;

$input-border-radius: 50rem;
$input-border-radius-lg: 50rem;
$input-group-addon-bg: #fff;
$input-group-addon-border-color: #fff;
$input-border-color: #fff;

$bg-color: #032741;
