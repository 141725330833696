.login {
    background-color: $bg-color;

    body {
        background-color: $bg-color;
    }

    label {
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
    }

    .form-control {
        border-left-width: 0;

        &::placeholder {
            color: #BDBEBE;
        }
    }

    .input-group-text {
        color: #BDBEBE;
    }
}
