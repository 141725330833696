// Header
.header {
    text-align: center;
    background-color: $bg-color;
    color: #fff;

    > div {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .masthead {
        width: 100%;
        max-width: 750px;
    }
}

.videos-inspired {
    background-color: #05A6CC;
    text-align: center;
    color: #FFF;
    padding: 0 2rem 2rem 2rem;

   h5, .h5 {
        font-weight: bold;
        @include media-breakpoint-between(xs,sm){
            font-size: 1em;
        }
    }
    .btn {
       
        max-width: 240px;
        @include media-breakpoint-between(xs,sm){
            font-size: 1em;
            width: 240px;
        }
    }
    i {
        margin-left: 7px;
    }
}

.power-balladd {
    background-color: $bg-color;
    text-align: center;
    color: #fff;
    h5 {
        padding: 1rem;
        margin: 0;
        text-transform: uppercase;
        color:#4bcae8;
               
        @include media-breakpoint-between(xs,sm){
            font-size: 0.9rem;
      
        }
    }
}

.audios {
    text-align: center;
    padding: 1.5rem;
    height: 100%;
    @include media-breakpoint-between(xs,sm){
        padding: 1rem;
    }

    .icon {
        height: 35px;
    }

    .btn {
        font-weight: normal;
        // min-height: 30px;

        @include media-breakpoint-between(xs,sm){
            height: 44px;
            font-size: 0.775rem;
            line-height: 1em;
        }
    }
}

.download-rfp {
    text-align: center;
    background-color: $bg-color;

    .btn {
        font-weight: bold;
    }
}
