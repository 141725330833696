.player-modal {
    .close {
        opacity: 1;
        text-shadow: none;
        color: #fff;
    }

    .modal-content {
        border-radius: 0;
        background-color: #0079A6;
        color: #fff;
        text-align: center;
    }

    a {
        color: #fff;
    }

    .modal-header {
        border-bottom-width: 0;
    }

    .player-cover {
        img {
            width: 250px;
            @include media-breakpoint-between(xs,sm){
              
                width: 200px;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }

        p {
            font-weight: bold;
            min-height: 4.5rem;
        }
    }

    .player-progess {
        .progress {
            height: 3px;
            margin-bottom: .25rem;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 0;
            cursor: pointer;
        }

        .progress-bar {
            background-color: #fff;
            transition: none;
            align-items: flex-end;

            &::after {
                content: ' ';
                display: block;
                width: 2px;
                height: 8px;
                background-color: black;
                position: absolute;
                background-color: white;
                margin-left: 1px;
            }
        }

        .player-time {
            font-size: .8rem;
        }
    }

    .player-title-author {
        margin-bottom: 3rem;
        min-height: 4.5rem;

        p {
            margin: 0;
        }
        .title {
            font-weight: bold;
        }
    }

    .player-controls {
        margin: 2rem;

        .prev, .next {
            font-size: 1.5rem;
        }

        .play {
            font-size: 3.5rem;
            margin: 0 1rem;
        }
    }

    .player-volume {
        .custom-range::-webkit-slider-runnable-track {
            height: 0.25rem;
            background-color: #fff;
            border-radius: 0;
        }

        .custom-range::-webkit-slider-thumb {
            margin-top: -0.375rem;
            background-color: #fff;
        }
    }
}
